<template>
  <div class="profilepage-container">
    <PageHeader :heading="user.name || ''" subHeading="Din bruker" backButtonText="Tilbake" />
    <div class="user-info">
      <div>Brukernavn</div>
      <div class="field-content">{{ user.email }}</div>
    </div>
    <div class="pass-info">
      <div>
        <div>Passord</div>
        <div class="field-content">****************</div>
      </div>
      <TextButton @click="resetPasswordHandler">Endre passord</TextButton>
    </div>
    <div class="log-out">
      <SecondaryButton @click="$router.push({ name: 'logOut' })">Logg ut</SecondaryButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextButton from '@/components/elements/TextButton.vue';
import SecondaryButton from '@/components/elements/SecondaryButton.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    TextButton,
    SecondaryButton,
    PageHeader,
  },

  computed: {
    ...mapState({
      user: state => state.user.cached || '',
    }),
  },

  methods: {
    async logOut() {
      await this.$store.dispatch('user/signOut');
      this.$router.push('/');
    },
    async resetPasswordHandler() {
      const result = await this.$store.dispatch('user/resetPassword', this.user.email);

      if (!result) {
        this.$notify({
          group: 'user',
          type: 'error',
          title: 'Det skjedde en feil.',
          text: 'Og vi er ikke helt sikre på hvorfor.. Prøv igjen eller kontakt admin',
        });
      } else {
        this.$notify({
          group: 'user',
          type: 'success',
          title: 'Vi har sendt deg en mail',
          text: 'med en link for å bytte passordet ditt',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profilepage-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}

.user-info,
.log-out {
  margin-top: $spacing-400;
}

.pass-info {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-200;
}

.field-content {
  font-weight: 600;
}
</style>
